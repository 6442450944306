import { useQueryClient } from '@tanstack/react-query';
import { Node } from 'djedi-react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import AddSVG from 'assets/icons/add.svg';
import Avatar from 'components/Avatar';
import Clickable, { ClickableProps } from 'components/Clickable';
import useMembership from 'hooks/useMembership';
import useUser from 'hooks/useUser';
import composeDisplayName from 'utils/composeDisplayName';
import cx from 'utils/cx';
import hasPrivilege from 'utils/hasPrivilege';
import DropdownLink from '../DropdownLink';
import classes from '../Header/Header.module.scss';

const ProfileLink: React.FC<
  {
    children: React.ReactNode;
    bold?: boolean;
    icon?: React.ReactNode;
  } & ClickableProps
> = ({ children, bold, icon, ...rest }) => (
  <>
    {icon}
    <Clickable className={cx(classes['link'], bold && classes['bold'])} variant="inline" {...rest}>
      {children}
    </Clickable>
  </>
);

const ProfileInfo: React.FC = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { user, isLoading: userIsLoading } = useUser();
  const { membership, setOrgId, isLoading: membershipIsLoading } = useMembership();

  if (userIsLoading || membershipIsLoading || user == null) {
    return null;
  }

  const displayName = composeDisplayName(user);
  // can only create new organizations if user is consultant in any organization (not sure why, ask Hugo)
  const canCreateOrganization = user.memberships.some(m => m.role === 'consultant');

  const orgChangeLinks = [
    ...user.memberships.map((m, i) => ({
      children: (
        <ProfileLink
          key={i}
          onClick={() => {
            setOrgId(m.organization.id);
            queryClient.clear();
            if (router.asPath === '/') {
              router.reload();
            } else {
              router.push('/', undefined);
            }
          }}
          disabled={membership.organization.id === m.organization.id}
          bold={true}
        >
          {m.organization.name}
        </ProfileLink>
      ),
      icon: (
        <Avatar
          type="organization"
          src={m.organization.image ?? undefined}
          size="small"
          shape="round"
        />
      ),
    })),
    ...(canCreateOrganization
      ? [
          {
            children: (
              <Link href="/organization/create">
                <Node uri="header/organization/create">Ny organisation</Node>
              </Link>
            ),
            icon: <AddSVG width={24} />,
          },
        ]
      : []),
  ];

  const linksData = [
    {
      href: hasPrivilege(membership.role, 'program_manager')
        ? '/profile/organization/'
        : '/profile/',
      label: membership.organization.name,
      icon: (
        <Avatar
          type="organization"
          src={membership.organization.image ?? undefined}
          size="small"
          shape="round"
        />
      ),
      bold: true,
    },
    {
      href: '/profile/organization',
      label: <Node uri="header/organization/settings">Organisationsinställningar</Node>,
      display: hasPrivilege(membership.role, 'program_manager'),
    },
    {
      href: '/organization/users',
      label: <Node uri="header/organization/users">Medlemmar</Node>,
      display: hasPrivilege(membership.role, 'program_manager'),
    },
    {
      customComponent: (
        <DropdownLink direction="vertical" links={orgChangeLinks}>
          <Node uri="header/organization/change">Byt organisation</Node>
        </DropdownLink>
      ),
      display: user.memberships.length >= 2 || hasPrivilege(membership.role, 'consultant'),
    },
    {
      customComponent: <hr />,
    },
    {
      href: '/profile',
      label: displayName,
      icon: <Avatar type="user" src={user.image ?? undefined} size="small" shape="round" />,
      bold: true,
    },
    {
      href: '/profile',
      label: <Node uri="header/user/profile">Användarprofil</Node>,
    },
    {
      href: '/logout',
      label: <Node uri="header/user/logout">Logga ut</Node>,
    },
  ];

  return (
    <div className={classes['profile']} tabIndex={0}>
      <Avatar src={user.image ?? undefined} type="user" className={classes['avatar']} />
      <Avatar
        src={membership.organization.image ?? undefined}
        type="organization"
        shape="round"
        className={classes['organization-image']}
      />
      <ul className={classes['links']}>
        {linksData.map(({ label, icon, customComponent, display, ...rest }, index) => {
          if (display === false) return null;
          return (
            customComponent ?? (
              <li key={index} className={cx(icon ? classes['icon-item'] : classes['item'])}>
                <ProfileLink icon={icon} {...rest}>
                  {label}
                </ProfileLink>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

export default ProfileInfo;
