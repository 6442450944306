import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { API_URL, CSRF_TOKEN_HEADER, ENDPOINTS } from 'api/consts';
import {
  DigitalDestinationSchema,
  MembershipSchema,
  OrganizationSchema,
  UpdateOrganizationInfoSchema,
  UpdateOrganizationSchema,
  UserSchema,
} from 'types/Changemkr';
import useLocalStorage from './useLocalStorage';
import useUser from './useUser';

export type MembershipContext = (
  | { isLoading: false; membership: Readonly<MembershipSchema> }
  | { isLoading: true; membership?: undefined }
) & {
  setOrgId: (orgId: number) => void;
  update: (
    changes: UpdateOrganizationSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  setInfo: (
    changes: UpdateOrganizationInfoSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  setImage: (
    image: string | File | Blob,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  setDestination: (
    changes: Partial<DigitalDestinationSchema>,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
};

export default function useMembership(): MembershipContext {
  const queryClient = useQueryClient();
  const { isLoading, user } = useUser();
  const [orgId, setOrgId] = useLocalStorage('orgId', -1);
  const membership = useMemo(() => {
    if (user == null) {
      return;
    }

    const membership =
      user.memberships.find(membership => membership.organization.id === orgId) ??
      user.memberships[0];

    if (orgId !== membership.organization.id) {
      setOrgId(orgId);
    }

    return membership;
  }, [user, orgId, setOrgId]);

  if (!isLoading && user && membership == null && orgId !== -1) {
    throw new Error(`Could not find membership containing organization with id ${orgId}`);
  }

  if (membership && membership.organization.id !== orgId) {
    setOrgId(membership.organization.id);
  }

  function updateOrganizationInUserSchema(organizationData: OrganizationSchema) {
    return (oldUser: UserSchema | undefined) => {
      if (oldUser === undefined) {
        return;
      }

      const newUser = structuredClone(oldUser);
      const membershipIndex = newUser.memberships.findIndex(
        membership => membership.organization.id === orgId,
      );
      newUser.memberships[membershipIndex].organization = organizationData;

      return newUser;
    };
  }

  const update = async (changes: UpdateOrganizationSchema) => {
    const response = await fetch(
      API_URL + ENDPOINTS.organization['update-organization'](membership!.organization.id),
      {
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify(changes),
        headers: {
          ...CSRF_TOKEN_HEADER,
          'Content-Type': 'application/json',
        },
      },
    );

    // Forbidden or unprocessable entity
    if (response.status === 403 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS.organization['update-organization']} request failed with ${response.status}`,
      );
    }

    queryClient.setQueryData<UserSchema>(
      ['user'],
      updateOrganizationInUserSchema((await response.json()) as OrganizationSchema),
    );

    return { success: true } as const;
  };

  const setInfo = async (changes: UpdateOrganizationInfoSchema) => {
    const response = await fetch(
      API_URL + ENDPOINTS.organization['update-pre-test'](membership!.organization.id),
      {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(changes),
        headers: {
          ...CSRF_TOKEN_HEADER,
          'Content-Type': 'application/json',
        },
      },
    );

    // Forbidden or unprocessable entity
    if (response.status === 403 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS.organization['update-pre-test'](
          membership!.organization.id,
        )} request failed with ${response.status}`,
      );
    }

    await Promise.all([
      queryClient.refetchQueries(['user']),
      queryClient.refetchQueries(['orgMembership', membership!.organization.id]),
    ]);

    return { success: true } as const;
  };

  const setImage = async (image?: File) => {
    if (image === undefined) {
      return;
    }

    const data = new FormData();
    data.append('image', image, image.name);

    const response = await fetch(
      API_URL + ENDPOINTS.organization['set-image'](membership!.organization.id),
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          ...CSRF_TOKEN_HEADER,
        },
        body: data,
      },
    );

    // Unauthorized or unprocessable entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.user['set-image']} request failed with ${response.status}`);
    }

    queryClient.refetchQueries(['user']);

    return { success: true } as const;
  };

  const setDestination = async (changes: Partial<DigitalDestinationSchema>) => {
    const response = await fetch(
      API_URL + ENDPOINTS.organization['set-digital-destination'](membership!.organization.id),
      {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(changes),
        headers: {
          ...CSRF_TOKEN_HEADER,
          'Content-Type': 'application/json',
        },
      },
    );

    // Forbidden or unprocessable entity
    if (response.status === 403 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS.organization['update-organization']} request failed with ${response.status}`,
      );
    }

    queryClient.setQueryData<UserSchema>(
      ['user'],
      updateOrganizationInUserSchema((await response.json()) as OrganizationSchema),
    );

    return { success: true } as const;
  };

  return {
    isLoading: isLoading || membership == null,
    membership,
    update,
    setImage,
    setDestination,
    setInfo,
    setOrgId,
  } as MembershipContext;
}
