import React, { useEffect, useState } from 'react';

import { API_URL } from 'api/consts';
import OrganizationSVG from 'assets/icons/organization.svg';
import ProfileSVG from 'assets/icons/profile.svg';
import Spinner from 'components/Spinner';
import { Color } from 'types/Colors';
import { WithClassName } from 'types/Component';
import { cx, fileToBase64 } from 'utils';
import classes from './Avatar.module.scss';

interface SharedAvatarProps {
  type?: 'organization' | 'user' | 'group';
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  outlined?: boolean;
  shape?: 'round' | 'rectangular';
}

export type AvatarProps = WithClassName &
  SharedAvatarProps &
  (
    | {
        src?: string | File | null;
      }
    | {
        initials?: string;
        color?: Color;
      }
  );

const Avatar: React.FC<AvatarProps> = ({
  type,
  className,
  size,
  outlined,
  shape,
  ...props
}: AvatarProps) => {
  type ??= 'user';
  size ??= 'medium';
  outlined ??= false;
  // if type is defined: organization is rectangular, user is round and group is round
  // if type is not defined: default to round
  shape ??= type === 'organization' ? 'rectangular' : 'round';

  const src = 'src' in props ? props.src : undefined;
  const initials = 'initials' in props ? props.initials : undefined;
  const color = 'color' in props ? props.color : 'initials' in props ? 'battleship-grey' : 'white';

  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState<string>();
  const sharedClasses = [
    classes[`size-${size}`],
    outlined && classes.outline,
    classes[`shape-${shape}`],
    classes[`color-${color}`],
  ];

  useEffect(() => {
    if (src instanceof File && base64 == null) {
      setLoading(true);
      fileToBase64(src)
        .then(setBase64)
        .then(() => setLoading(false));
    }
  }, [src, base64]);

  if (initials) {
    return (
      <div className={cx(sharedClasses, classes.initials, className)}>
        {initials.toLocaleUpperCase()}
      </div>
    );
  }

  return loading ? (
    <Spinner fill />
  ) : src != null ? (
    <img
      alt="Your avatar"
      src={typeof src === 'string' ? (src.startsWith('http') ? src : `${API_URL}${src}`) : base64}
      className={cx(sharedClasses, classes['image'], className)}
    />
  ) : type === 'user' ? (
    <ProfileSVG className={cx(sharedClasses, className)} />
  ) : (
    <OrganizationSVG className={cx(sharedClasses, className)} />
  );
};

export default Avatar;
