export default function composeDisplayName<
  T extends (
    | {
        email: string;
      }
    | {
        phone: string;
      }
  ) & {
    firstname?: string;
    lastname?: string;
  },
>({ firstname, lastname, ...rest }: T): string {
  const hasFirstName = firstname && firstname !== '';
  const hasLastName = lastname && lastname !== '';
  const phone = ('phone' in rest ? rest.phone : undefined) as string | undefined;
  const email = ('email' in rest ? rest.email : undefined) as string | undefined;

  if (hasFirstName && hasLastName) {
    return `${firstname} ${lastname}`;
  }

  if (hasFirstName) {
    return `${firstname} (${email ?? phone})`;
  }

  if (hasLastName) {
    return `${lastname} (${email ?? phone})`;
  }

  return (email ?? phone)!;
}
