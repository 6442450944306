/**
 * Picks the N:th entry in the {@link options} array depending on the value of
 * {@link n} where a value of 0 is equal to the first element and 1 is equal to
 * the last element.
 *
 * @param options A generic array of all the options
 * @param n A number between 0 to 1
 */
export default function pickFromOptions<T>(options: ReadonlyArray<T>, n: number): T {
  return options[Math.floor(n * options.length)] ?? options[options.length - 1];
}
