import { Color } from 'types/Colors';
import { WithClassName } from 'types/Component';
import { cx } from 'utils';
import classes from './Spinner.module.scss';

export interface SpinnerProps extends WithClassName {
  color?: Color;
  size?: 'extra-large' | 'large' | 'medium' | 'small';
  fill?: boolean;
  padding?: number;
  maxHeight?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ className, color, size, fill, padding, maxHeight }) => {
  size ??= 'small';
  color ??= 'sunset';
  fill ??= false;

  const style: React.CSSProperties = {};

  if (padding) style.padding = `${padding * 16}px`;
  if (maxHeight) style.maxHeight = `${maxHeight}px`;

  return (
    <div className={cx(classes['container'], fill && classes['fill'], className)} style={style}>
      <div className={cx(classes['spinner'], classes[`size-${size}`], classes[`color-${color}`])} />
    </div>
  );
};

export default Spinner;
