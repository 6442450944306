import { ForwardedRef, forwardRef, ReactNode } from 'react';

import { WithChildren, WithClassName, WithRef } from 'types/Component';
import { cx } from 'utils';
import classes from './Row.module.scss';

interface RowProps extends WithChildren, WithClassName, WithRef<HTMLDivElement> {
  center?: 'horizontal' | 'vertical' | 'both';
  children: ReactNode;
  gap?: 'double' | 'single' | 'none';
}

const Row = forwardRef(function Row(
  { center, children, className, gap = 'single' }: RowProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={cx(
        classes['container'],
        center && classes[`center-${center}`],
        classes[`gap-${gap}`],
        className,
      )}
    >
      {children}
    </div>
  );
});

export default Row;
