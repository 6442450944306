import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';

import { API_URL, CSRF_TOKEN_HEADER, ENDPOINTS } from 'api/consts';
import {
  BulkUpdateTaskSchema,
  CreateTaskSchema,
  PagedPlanSchema,
  PlanSchema,
  TaskDetailSchema,
  UpdateTaskSchema,
  WritePlanSchema,
} from 'types/Changemkr';

export type PlanContext = (
  | { isLoading: false; plan?: Readonly<PlanSchema> }
  | { isLoading: true; plan?: undefined }
) & {
  createTask: (
    task: CreateTaskSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  updateTask: (
    taskId: number,
    changes: UpdateTaskSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  deleteTask: (
    taskId: number,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  updateTasks: UseMutationResult<
    { success: true; reason?: undefined },
    { success: false; reason: string },
    BulkUpdateTaskSchema[]
  >;
  updatePlan: (
    changes: WritePlanSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
};

export default function usePlan(orgId: number, planId?: number): PlanContext {
  const { isLoading: plansIsLoading, data: plans } = usePlans(orgId, { enabled: planId == null });
  const id = planId ?? plans?.items[0]?.id;
  const queryClient = useQueryClient();
  const { isLoading: planIsLoading, data: plan } = useQuery(
    ['plan', id],
    async () => {
      const response = await fetch(API_URL + ENDPOINTS.plan.retrieve(id!), {
        method: 'GET',
        credentials: 'include',
        headers: {
          ...CSRF_TOKEN_HEADER,
        },
      });

      if (response.status === 404) {
        throw new Error(`No plan with id ${id} could be found`);
      }

      if (!response.ok) {
        throw new Error(`${ENDPOINTS.plan.retrieve(id!)} request failed with ${response.status}`);
      }

      return (await response.json()) as PlanSchema;
    },
    {
      enabled: id != null,
    },
  );

  const updateTasks = useMutation({
    onMutate: changes => {
      queryClient.setQueryData<PlanSchema>(['plan', id], oldPlan => {
        if (oldPlan === undefined || typeof oldPlan.tasks === 'undefined') {
          return;
        }

        const newPlan = structuredClone(oldPlan);

        for (const changedTask of changes) {
          const taskIndex = newPlan.tasks!.findIndex(task => task.id === changedTask.id);
          newPlan.tasks![taskIndex] = { ...newPlan.tasks![taskIndex], ...changedTask };
        }

        return newPlan;
      });

      for (const changedTask of changes) {
        queryClient.setQueryData<TaskDetailSchema>(['task', changedTask.id], oldTask => {
          if (oldTask === undefined) {
            return;
          }

          return { ...oldTask, ...changedTask };
        });
      }
    },
    mutationFn: async (changes: BulkUpdateTaskSchema[]) => {
      const response = await fetch(API_URL + ENDPOINTS.tasks['bulk-update'], {
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify(changes),
        headers: {
          ...CSRF_TOKEN_HEADER,
          'Content-Type': 'application/json',
        },
      });

      // Unauthorized or Unprocessable Entity
      if (response.status === 401 || response.status === 422) {
        return { success: false, reason: (await response.json()).detail as string } as const;
      }

      if (!response.ok) {
        throw new Error(`${ENDPOINTS.auth.login} request failed with ${response.status}`);
      }

      return { success: true } as const;
    },
    onSettled: () => {
      queryClient.refetchQueries(['plan', id]);
    },
  });

  const updateTask = async (taskId: number, changes: UpdateTaskSchema) => {
    queryClient.setQueryData<PlanSchema>(['plan', id], oldPlan => {
      if (oldPlan === undefined || typeof oldPlan.tasks === 'undefined') {
        return;
      }

      const taskIsSubtask = !oldPlan.tasks!.some(task => task.id === taskId);
      const newPlan = structuredClone(oldPlan);
      const taskIndex = newPlan.tasks!.findIndex(task => {
        return task.subtasks?.some(subtask => {
          return subtask.id === taskId;
        });
      });

      // If task is subtask
      if (taskIsSubtask) {
        const subtaskIndex = newPlan.tasks![taskIndex].subtasks!.findIndex(subtask => {
          return subtask.id === taskId;
        });

        newPlan.tasks![taskIndex].subtasks![subtaskIndex] = {
          ...newPlan.tasks![taskIndex].subtasks![subtaskIndex],
          ...changes,
        };

        // Update parent task
        queryClient.setQueryData<TaskDetailSchema>(
          ['task', newPlan.tasks![taskIndex].id],
          oldParent => {
            const newParent = structuredClone(oldParent);

            if (newParent?.subtasks && taskIndex !== -1) {
              const subtaskIndex = newParent.subtasks!.findIndex(subtask => {
                return subtask.id === taskId;
              });

              newParent.subtasks[subtaskIndex] = {
                ...newParent.subtasks[subtaskIndex],
                ...changes,
              };
              return newParent;
            }
          },
        );
      } else {
        // If task is task (as opposed to subtask)
        newPlan.tasks![taskIndex] = { ...newPlan.tasks![taskIndex], ...changes };
      }

      return newPlan;
    });

    queryClient.setQueryData<TaskDetailSchema>(['task', taskId], oldTask => {
      if (oldTask === undefined) {
        return;
      }

      const {
        responsible_users: _0,
        participant_groups: _1,
        participant_users: _2,
        ...overwriteableChanges
      } = changes;

      return { ...oldTask, ...overwriteableChanges };
    });

    const response = await fetch(API_URL + ENDPOINTS.tasks.update(taskId), {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify(changes),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Unauthorized or Unprocessable Entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.tasks.update(taskId)} request failed with ${response.status}`);
    }

    const taskData = (await response.json()) as TaskDetailSchema;

    queryClient.setQueryData<TaskDetailSchema>(['task', taskId], taskData);
    queryClient.refetchQueries(['plan', id]);

    return { success: true } as const;
  };

  const deleteTask = async (taskId: number) => {
    const response = await fetch(API_URL + ENDPOINTS.tasks.delete(taskId), {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        ...CSRF_TOKEN_HEADER,
      },
    });

    // Unauthorized or Unprocessable Entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.tasks.delete(taskId)} request failed with ${response.status}`);
    }

    queryClient.setQueryData<PlanSchema>(['plan', id], oldPlan => {
      if (oldPlan === undefined || typeof oldPlan.tasks === 'undefined') {
        return;
      }

      const taskIsSubtask = !oldPlan.tasks!.some(task => task.id === taskId);
      const newPlan = structuredClone(oldPlan);
      const taskIndex = newPlan.tasks!.findIndex(task => {
        return task.subtasks?.some(subtask => {
          return subtask.id === taskId;
        });
      });

      // If task is subtask
      if (taskIsSubtask) {
        const subtaskIndex = newPlan.tasks![taskIndex].subtasks!.findIndex(subtask => {
          return subtask.id === taskId;
        });

        newPlan.tasks![taskIndex].subtasks!.splice(subtaskIndex, 1);
      } else {
        // If task is task (as opposed to subtask)
        newPlan.tasks!.splice(taskIndex, 1);
      }

      return newPlan;
    });

    queryClient.setQueryData<TaskDetailSchema>(['task', taskId], oldTask => {
      if (oldTask?.parent_id != null) {
        queryClient.setQueryData<TaskDetailSchema>(['task', oldTask.parent_id], oldParent => {
          const newParent = structuredClone(oldParent);
          const taskIndex = newParent?.subtasks?.findIndex(task => task.id === taskId);

          if (newParent?.subtasks && taskIndex != null && taskIndex !== -1) {
            newParent.subtasks.splice(taskIndex, 1);
            return newParent;
          }
        });
      }

      return undefined;
    });

    return { success: true } as const;
  };

  const createTask = async (task: CreateTaskSchema) => {
    const response = await fetch(API_URL + ENDPOINTS.tasks.create, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(task),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Unauthorized or Unprocessable Entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.tasks.create} request failed with ${response.status}`);
    }

    const taskResponse = (await response.json()) as TaskDetailSchema;

    queryClient.setQueryData<PlanSchema>(['plan', id], oldPlan => {
      if (oldPlan === undefined || typeof oldPlan.tasks === 'undefined') {
        return;
      }

      const taskIsSubtask = task.parent_id != null;
      const newPlan = structuredClone(oldPlan);

      if (!taskIsSubtask) {
        newPlan.tasks!.push(taskResponse);
      }

      return newPlan;
    });
    queryClient.setQueryData<TaskDetailSchema>(['task', taskResponse.id], taskResponse);

    // Ugh yeah we need to set even more query data in that case
    if (taskResponse.parent_id != null) {
      queryClient.setQueryData<TaskDetailSchema>(['task', taskResponse.parent_id], oldTask => {
        if (oldTask === undefined) {
          return;
        }

        const newTask = structuredClone(oldTask);
        newTask.subtasks ??= [];
        newTask.subtasks.push({
          id: taskResponse.id,
          title: taskResponse.title,
          description: taskResponse.description,
          finished: taskResponse.finished,
        });

        return newTask;
      });

      queryClient.setQueryData<PlanSchema>(['plan', id], oldPlan => {
        if (oldPlan === undefined || typeof oldPlan.tasks === 'undefined') {
          return;
        }

        const newPlan = structuredClone(oldPlan);
        const taskIndex = newPlan.tasks!.findIndex(task => task.id === taskResponse.parent_id);
        newPlan.tasks![taskIndex].subtasks ??= [];
        newPlan.tasks![taskIndex].subtasks!.push({
          id: taskResponse.id,
          title: taskResponse.title,
          description: taskResponse.description,
          finished: taskResponse.finished,
        });

        return newPlan;
      });
    }

    return { success: true } as const;
  };

  const updatePlan = async (changes: WritePlanSchema) => {
    const response = await fetch(API_URL + ENDPOINTS.plan.update(id!), {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify(changes),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Forbidden or Unprocessable Entity
    if (response.status === 403 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.plan.update(id!)} request failed with ${response.status}`);
    }

    const planData = (await response.json()) as PlanSchema;
    queryClient.setQueryData<PlanSchema>(['plan', id], planData);

    return { success: true } as const;
  };

  return {
    isLoading: (plansIsLoading || planIsLoading) && id != null,
    plan,
    updateTasks,
    updateTask,
    createTask,
    updatePlan,
    deleteTask,
  } as PlanContext;
}

export function usePlans(orgId: number, options: { enabled?: boolean } = { enabled: true }) {
  const queryClient = useQueryClient();
  return useQuery(
    ['plans', orgId],
    async () => {
      const response = await fetch(API_URL + ENDPOINTS.plan.list(orgId), {
        method: 'GET',
        credentials: 'include',
        headers: {
          ...CSRF_TOKEN_HEADER,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`${ENDPOINTS.plan.list} request failed with ${response.status}`);
      }

      const plans = (await response.json()) as PagedPlanSchema;

      for (const plan of plans.items) {
        queryClient.setQueryData(['plan', plan.id], plan);
      }

      return plans;
    },
    { enabled: options.enabled ?? true },
  );
}
