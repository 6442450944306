import React from 'react';
import Link from 'next/link';

import PadlockIcon from 'assets/icons/padlock.svg';
import Spinner from 'components/Spinner';
import { Color } from 'types/Colors';
import { WithChildren, WithClassName } from 'types/Component';
import { cx } from 'utils';
import classes from './Clickable.module.scss';

interface BaseProps extends WithClassName, WithChildren {
  'active'?: boolean;
  'aria-selected'?: boolean;
  'background'?: Color;
  'disabled'?: boolean;
  'foreground'?: Color;
  'hollow'?: boolean;
  'hover'?: Color;
  'role'?: React.AriaRole;
  'small'?: boolean;
  'variant'?: 'button' | 'inline' | 'block';
  'style'?: React.CSSProperties;
  'id'?: string;
}

export interface AnchorProps extends BaseProps {
  href: string;
  target?: React.HTMLAttributeAnchorTarget;
}

export interface ButtonProps extends BaseProps {
  loading?: boolean;
  onClick?: (() => void) | (() => Promise<void>);
  type?: 'submit';
}

export type ClickableProps = AnchorProps | ButtonProps;

const Clickable: React.FC<ClickableProps> = ({
  active,
  background = 'sunset',
  className,
  disabled,
  small,
  hollow,
  hover = 'orange',
  variant = 'button',
  foreground = variant === 'inline' ? 'black' : 'white',
  ...props
}) => {
  const classNames = cx(
    classes['container'],
    classes[variant],
    classes[`background-${background}`],
    classes[`foreground-${foreground}`],
    classes[`hover-${hover}`],
    disabled && classes['disabled'],
    small && classes['small'],
    hollow && classes['hollow'],
    active && classes['active'],
    className,
  );

  const Anchor = ({ children, href, ...linkProps }: Omit<AnchorProps, 'style'>) => {
    return (
      <Link {...linkProps} href={disabled ? '' : href} passHref className={classNames}>
        {disabled ? <PadlockIcon /> : null}
        {children}
      </Link>
    );
  };

  if ('href' in props) {
    return <Anchor {...props} />;
  } else {
    const { children, loading, ...buttonProps } = props;
    return (
      <button {...buttonProps} disabled={disabled} className={classNames}>
        {loading && (
          <Spinner color={disabled ? 'sunset' : foreground} className={classes['spinner']} />
        )}
        {children}
      </button>
    );
  }
};

export default Clickable;
