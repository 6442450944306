import { FC, useRef } from 'react';
import {
  AriaDialogProps,
  AriaOverlayProps,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';

import { WithChildren, WithClassName } from 'types/Component';
import { cx } from 'utils';
import classes from './Modal.module.scss';

interface ModalProps extends WithClassName, WithChildren, AriaOverlayProps, AriaDialogProps {}

const Modal: FC<ModalProps> = props => {
  const { children, className } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(props, ref);

  usePreventScroll();

  const { dialogProps } = useDialog(props, ref);
  const { modalProps } = useModal();

  return (
    <div className={cx(classes['container'], className)} {...underlayProps}>
      <div {...overlayProps} {...modalProps} {...dialogProps} ref={ref}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
