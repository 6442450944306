import { createElement, ReactNode } from 'react';

import { Color, colors } from 'types/Colors';
import { cx } from 'utils';
import classes from './Heading.module.scss';

export interface HeadingProps {
  children: ReactNode;
  className?: string;
  color?: Color;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'b';
  weight?: 'bold' | 'regular';
}

const Heading = ({
  children,
  className,
  color = 'deathstar-grey',
  type = 'b',
  weight,
}: HeadingProps) => {
  return createElement(
    type,
    {
      className: cx(classes['container'], weight && classes[weight], className),
      style: {
        color: colors[color],
      },
    },
    children,
  );
};

export default Heading;
