import { forwardRef } from 'react';

import { Color } from 'types/Colors';
import { WithChildren, WithClassName } from 'types/Component';
import { cx } from 'utils';
import classes from './Typography.module.scss';

export interface TypographyProps
  extends WithClassName,
    WithChildren,
    Omit<JSX.IntrinsicElements['p'], 'style' | 'ref'> {
  color?: Color;
  size?: 'extra-large' | 'large' | 'regular' | 'small' | 'xsmall';
  style?: 'semibold' | 'bold' | 'italic';
}

const Typography = forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ children, className, color, size, style, ...rest }, ref) => (
    <p
      className={cx(
        classes['container'],
        color && classes[`color-${color}`],
        size && classes[`size-${size}`],
        style && classes[`style-${style}`],
        className,
      )}
      {...rest}
      ref={ref}
    >
      {children}
    </p>
  ),
);

Typography.displayName = 'Typography';

export default Typography;
