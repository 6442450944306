import React, { ReactNode } from 'react';
import { ForceNodes, Node } from 'djedi-react';
import Head from 'next/head';

import Footer from 'components/Footer';
import Header from 'components/header/Header';
import HelperLinks from 'components/HelperLinks';
import { cx } from 'utils';
import classes from './Layout.module.scss';

export default function Layout({
  children,
  className,
  title,
  description,
  image,
  gap,
  noHeader,
  backgroundColor,
  ...contentWrapperProps
}: LayoutProps): JSX.Element {
  return (
    <main
      className={cx(
        classes['container'],
        noHeader && classes['no-header'],
        backgroundColor && classes[backgroundColor],
      )}
    >
      {title != null &&
        (typeof title === 'string' ? (
          <Head>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            {description && <meta property="og:description" content={description} />}
            {image && <meta property="og:image" content={image} />}
          </Head>
        ) : (
          title.type === Node &&
          React.cloneElement(title, {
            edit: false,
            render: DjediHeadTitle,
          })
        ))}

      {!noHeader && (
        <>
          <HelperLinks />
          <Header />
        </>
      )}

      <article
        {...contentWrapperProps}
        className={cx(classes['content'], gap && classes['gap'], className)}
      >
        {children}
      </article>

      <Footer />
    </main>
  );
}

const basePageTitleNode = <Node uri="page-title">Changemkr</Node>;

function HeadTitle({ title }: { title: string }): JSX.Element {
  const render = (state: DjediNodeState) => {
    title = title.trim();
    const suffix = ` - ${state.content}`;
    const fullTitle =
      title === '' ? state.content : title.endsWith(suffix) ? title : `${title}${suffix}`;

    if (state.type === 'success') {
      return (
        <Head>
          <title>{fullTitle}</title>
          <meta property="og:title" content={fullTitle} />
        </Head>
      );
    }

    return null;
  };

  return (
    <>
      {React.cloneElement(basePageTitleNode, {
        edit: false,
        render,
      })}
      <ForceNodes>{basePageTitleNode}</ForceNodes>
    </>
  );
}

const DjediHeadTitle: React.FC<DjediNodeState> = state => (
  <HeadTitle title={state.type === 'success' ? state.content : ''} />
);

export interface LayoutProps
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'title'
  > {
  children?: ReactNode;
  title?: string | React.ReactElement;
  description?: string;
  image?: string;
  gap?: boolean;
  noHeader?: boolean;
  backgroundColor?: 'pearl';
}

interface DjediNodeState {
  content: string & React.ReactElement;
  type: 'loading' | 'error' | 'success';
}
