import { TaskDetailSchema, TaskSchema } from 'types/Changemkr';

export default function showInPlan(task: TaskDetailSchema | TaskSchema) {
  return (
    task.status !== 'BACKLOG' &&
    task.status !== 'ARCHIVED' &&
    task.plan_order != null &&
    task.parent_id == null
  );
}
