import { Node } from 'djedi-react';

import Clickable from 'components/Clickable';
import classes from './HelperLinks.module.scss';

const HelperLinks = () => {
  return (
    <div className={classes['background']}>
      <div className={classes['container']}>
        <Clickable className={classes['link']} href="/help/glossary" variant="inline">
          <Node uri="help-links/glossary">Ordlista</Node>
        </Clickable>
        <Clickable className={classes['link']} href="/help/about" variant="inline">
          <Node uri="help-links/about">Om oss</Node>
        </Clickable>
        <Clickable className={classes['link']} href="/help/support" variant="inline">
          <Node uri="help-links/support">Kundsupport</Node>
        </Clickable>
        <Clickable
          className={classes['link']}
          href="/privacy-policy/"
          variant="inline"
          target="_blank"
        >
          <Node uri="help-links/privacy-policy">Dataskyddspolicy</Node>
        </Clickable>
      </div>
    </div>
  );
};

export default HelperLinks;
