import { useEffect, useState } from 'react';

export default function useLocalStorage<T>(key: string, defaultValue: T) {
  // If a user object is provided a user unique key will be created

  const [state, setState] = useState<T>(getState(key) ?? defaultValue);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (mounted) return;
    if (typeof localStorage !== 'undefined') {
      const value = getState<T>(key);
      if (value !== null) setState(value);
      setMounted(true);
    }
  }, [key, mounted]);

  useEffect(() => {
    if (mounted) setLocalState(key, state);
  }, [state, key, mounted]);

  return [state, setState] as const;
}

export function getState<T>(key: string): T | null {
  return typeof localStorage !== 'undefined'
    ? tryJSONParse<T>(localStorage.getItem(buildStorageKey(key)))
    : null;
}

export function setLocalState<T>(key: string, state: T) {
  localStorage.setItem(buildStorageKey(key), JSON.stringify(state));
}

function buildStorageKey(key: string) {
  return `__useLocalStorage_${key}`;
}

export function tryJSONParse<T>(json: string | null | undefined): T | null {
  if (!json) return null;
  try {
    return JSON.parse(json) as T;
  } catch (e) {
    return null;
  }
}
