import clsx from 'classnames';

import { WithChildren, WithClassName, WithRef } from 'types/Component';
import classes from './Column.module.scss';

export type ColumnSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
export type ColumnBreakpoints =
  | ColumnSizes
  | [ColumnSizes, ColumnSizes]
  | [ColumnSizes, ColumnSizes, ColumnSizes]
  | [ColumnSizes, ColumnSizes, ColumnSizes, ColumnSizes];

export interface ColumnProps extends WithClassName, WithChildren, WithRef<HTMLDivElement> {
  center?: 'horizontal' | 'vertical' | 'both';
  // Which column to start from, not relative to current position.
  onClick?: () => void;
  size: ColumnBreakpoints;
  start?: ColumnBreakpoints;
}

const DEVICES = ['sm', 'md', 'lg', 'xl'];

const Column: React.FC<ColumnProps> = ({
  center,
  children,
  className,
  onClick,
  ref,
  size,
  start,
}: ColumnProps) => {
  const sizes = Array.isArray(size)
    ? size.map((a, i) => classes[[DEVICES[i], a].join('-')])
    : classes[`size-${size}`];

  const starts = Array.isArray(start)
    ? start.map((a, i) => (a !== null ? classes[['start', DEVICES[i], a].join('-')] : undefined))
    : classes[`start-${start}`];

  return (
    <div
      className={clsx(
        classes['container'],
        center && classes[`center-${center}`],
        sizes,
        starts,
        className,
      )}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default Column;
