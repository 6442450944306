import ArrowDownSVG from 'assets/icons/arrow-down.svg';
import Clickable from 'components/Clickable';
import { cx } from 'utils';
import classes from '../Header/Header.module.scss';

interface BasicLinkProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  href?: string;
}
interface LinkProps extends BasicLinkProps {
  inSubmenu?: true;
  links?: BasicLinkProps[];
  direction?: 'horisontal' | 'vertical';
  showSeparators?: boolean;
  active?: boolean;
}

const DropdownLink: React.FC<LinkProps> = ({
  children,
  href,
  icon,
  inSubmenu,
  links,
  active,
  direction = 'horisontal',
  showSeparators = false,
}) => {
  const hasSubmenu = links && Array.isArray(links);
  const hasIcon = inSubmenu && icon;

  return (
    <li className={cx(hasIcon ? classes['icon-item'] : classes['item'])}>
      {hasSubmenu ? (
        <>
          <span className={cx(classes['label'], classes[direction])} tabIndex={0}>
            {children}
            <ArrowDownSVG className={classes['icon']} />
          </span>
          <ul className={cx(classes['submenu'], classes[direction])} role="menu">
            {showSeparators
              ? links.flatMap((a, i, arr) => {
                  const linkElement = <DropdownLink inSubmenu {...a} key={i} />;

                  if (i === arr.length - 1) {
                    return [linkElement];
                  }

                  return [linkElement, <hr key={`hr-${i}`} />];
                })
              : links.map((a, i) => <DropdownLink inSubmenu {...a} key={i} />)}
          </ul>
        </>
      ) : (
        <Clickable
          active={active ?? Boolean(href && href.endsWith(window.location.pathname))}
          variant={inSubmenu ? 'inline' : 'block'}
          className={cx(classes['link'], hasIcon && classes['flex'])}
          {...(href ? { href } : {})}
        >
          {hasIcon && icon}
          {children}
        </Clickable>
      )}
    </li>
  );
};

export default DropdownLink;
