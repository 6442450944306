import cx from 'classnames';

import classes from './Container.module.scss';

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  narrow?: boolean;
}

const Container: React.FC<ContainerProps> = ({ children, className, narrow }) => {
  return <div className={cx(classes.root, narrow && classes.narrow, className)}>{children}</div>;
};

export default Container;
