import { Node } from 'djedi-react';

import { TaskSchema } from 'types/Changemkr';
import { TaskStatus } from 'types/Changemkr';
import { DragAndDropListInterface } from '../../types/DragAndDrop';

export function dragAndDropListTitle(status: TaskStatus) {
  switch (status) {
    case 'ARCHIVED':
      return <Node uri="glossary/status/archived">Arkiverat</Node>;
    case 'BACKLOG':
      return <Node uri="glossary/status/backlog">För utvärdering</Node>;
    case 'DONE':
      return <Node uri="glossary/status/done">Klart</Node>;
    case 'IN_PROGRESS':
      return <Node uri="glossary/status/in-progress">Påbörjat</Node>;
    case 'NOT_STARTED':
      return <Node uri="glossary/status/not-started">Att göra</Node>;
  }
}

export default function toDragAndDropLists(tasks: TaskSchema[]): DragAndDropListInterface[] {
  const columns: Record<TaskStatus, DragAndDropListInterface> = {
    ARCHIVED: {
      id: 'ARCHIVED',
      items: [],
      title: <Node uri="glossary/status/archived">Arkiverat</Node>,
    },
    BACKLOG: {
      id: 'BACKLOG',
      items: [],
      title: <Node uri="glossary/status/backlog">För utvärdering</Node>,
    },
    DONE: {
      id: 'DONE',
      items: [],
      title: <Node uri="glossary/status/done">Klart</Node>,
    },
    IN_PROGRESS: {
      id: 'IN_PROGRESS',
      items: [],
      title: <Node uri="glossary/status/in-progress">Påbörjat</Node>,
    },
    NOT_STARTED: {
      id: 'NOT_STARTED',
      items: [],
      title: <Node uri="glossary/status/not-started">Att göra</Node>,
    },
  };

  for (const task of tasks) {
    columns[task.status].items.push({
      id: task.id.toString(),
      task,
    });
  }

  return Object.values(columns).map((column: DragAndDropListInterface) => {
    column.items = column.items.sort((a, b) => a.task.order - b.task.order);
    return column;
  });
}
