import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import cookies from 'js-cookie';

import { API_URL, CSRF_TOKEN_HEADER, ENDPOINTS } from 'api/consts';
import { UpdateUserProfileSchema, UserSchema } from 'types/Changemkr';

export type UserContext = (
  | { isLoading: false; isAuthenticated: true; user: Readonly<UserSchema> }
  | { isLoading: boolean; isAuthenticated: false; user?: undefined }
) & {
  login: (
    email: string,
    password: string,
    remember_me?: boolean,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  acceptInvitation: (
    token: string,
    password: string,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  logout: () => Promise<void>;
  update: (
    changes: UpdateUserProfileSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  setImage: (
    image: string | File | Blob,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
};

export default function useUser(): UserContext {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    data: user,
  } = useQuery(
    ['user'],
    async () => {
      const response = await fetch(API_URL + ENDPOINTS.auth.me, {
        method: 'GET',
        credentials: 'include',
        headers: {
          ...CSRF_TOKEN_HEADER,
        },
      });

      if (!response.ok) {
        throw new Error(`${ENDPOINTS.auth.me} request failed with ${response.status}`);
      }

      return (await response.json()) as UserSchema;
    },
    { retry: false },
  );

  const isAuthenticated = useMemo(() => {
    if (isLoading || isError) {
      return false;
    }

    // just for good measure
    // eslint-disable-next-line
    return user != null;
  }, [isLoading, isError, user]);

  const login = async (email: string, password: string, remember_me?: boolean) => {
    cookies.remove('sessionid');

    const response = await fetch(API_URL + ENDPOINTS.auth.login, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ email, password, remember_me }),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Unauthorized or unprocessable entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.auth.login} request failed with ${response.status}`);
    }

    queryClient.setQueryData(['user'], (await response.json()) as UserSchema);

    return { success: true } as const;
  };

  const acceptInvitation = async (token: string, password: string) => {
    cookies.remove('sessionid');

    const response = await fetch(API_URL + ENDPOINTS.auth['accept-invitation'], {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ token, password }),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Unprocessable entity
    if (response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.auth.login} request failed with ${response.status}`);
    }

    // The user still needs to login even after accepting an invitiation
    // Uncomment this if that ever changes
    // queryClient.refetchQueries(['user']);

    return { success: true } as const;
  };

  const logout = async () => {
    const response = await fetch(API_URL + ENDPOINTS.auth.logout, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...CSRF_TOKEN_HEADER,
      },
    });

    if (!response.ok) {
      console.warn(`${ENDPOINTS.auth.logout} request failed with ${response.status}`);
    }

    queryClient.setQueryData(['user'], undefined);
    cookies.remove('sessionid');
    cookies.remove('csrftoken');
  };

  const update = async (changes: UpdateUserProfileSchema) => {
    const response = await fetch(API_URL + ENDPOINTS.user['update-profile'], {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify(changes),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Unauthorized or unprocessable entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.user['update-profile']} request failed with ${response.status}`);
    }

    queryClient.setQueryData(['user'], (await response.json()) as UserSchema);

    return { success: true } as const;
  };

  const setImage = async (image?: File) => {
    if (image === undefined) {
      return;
    }

    const data = new FormData();
    data.append('image', image, image.name);

    const response = await fetch(API_URL + ENDPOINTS.user['set-image'], {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...CSRF_TOKEN_HEADER,
      },
      body: data,
    });

    // Unauthorized or unprocessable entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(`${ENDPOINTS.user['set-image']} request failed with ${response.status}`);
    }

    queryClient.refetchQueries(['user']);

    return { success: true } as const;
  };

  return {
    isLoading: isLoading && !isError,
    isAuthenticated,
    user,
    login,
    acceptInvitation,
    logout,
    update,
    setImage,
  } as UserContext;
}
