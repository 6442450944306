import LightLogo from 'assets/images/logo-white-text.svg';
import Typography from 'components/Typography';
import classes from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={classes['container']}>
      <div className={classes['inner']}>
        <LightLogo className={classes['logo']} />
        <Typography color="white" size="small">
          © {new Date().getFullYear()} Changemkr
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
