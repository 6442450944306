import { MembershipRole } from 'types/Changemkr';

export const CSRF_COOKIE_NAME = 'csrftoken';
export const MODAL_ROOT_ID = 'modal-root';
export const CLOSE_KEYS = ['Undo', 'Escape', 'Cancel', 'GoBack', 'GoHome'];
export const MOCK_API_URL = 'http://localhost:8000/mock-api';
export const COOKIE_CONSENT_COOKIE_NAME = 'cookies_ok';

export const MOTORS = [
  'VALUES_VISION_MISSION',
  'STRATEGY',
  'ORGANIZATION',
  'PROCESSES',
  'INFRASTRUCTURE',
  'DATA_AND_ANALYSIS',
  'VALUE_PROPOSITION',
  'TOUCHPOINTS',
  'RELATIONSHIPS',
] as const;
export const PHASES = ['Mobilization', 'Coordination', 'Acceleration'] as const;
export const BALANCE = ['behind', 'balanced', 'ahead'] as const;
export const ROLE_PRECEDENCE: Record<MembershipRole, number> = {
  member: 0,
  program_manager: 1,
  program_owner: 2,
  consultant: 3,
} as const;

export const PLANNER_INSPIRATION_ARTICLE_SLUGS = [
  'grunderna-i-artificiell-intelligens-for-foretag-2',
  'grunderna-i-artificiell-intelligens-for-foretag-2',
];
