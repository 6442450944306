import React, { ComponentType } from 'react';

import useMembership from 'hooks/useMembership';
import { MembershipSchema } from 'types/Changemkr';

export type WithOrgIdProps = {
  orgId: number;
  membership: MembershipSchema;
};

const withOrgId = <P extends object>(InnerComponent: ComponentType<P & WithOrgIdProps>) => {
  const WrappedComponent: React.FC<Omit<P, keyof WithOrgIdProps>> = props => {
    const { isLoading: membershipIsLoading, membership } = useMembership();

    if (membershipIsLoading) return null;
    const orgId = membership.organization.id;

    return <InnerComponent {...(props as P)} orgId={orgId} membership={membership} />;
  };

  WrappedComponent.displayName = `WithOrgId(${getDisplayName(InnerComponent)})`;

  return WrappedComponent;
};

function getDisplayName<T>(WrappedComponent: ComponentType<T>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withOrgId;
