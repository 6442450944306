import { useState } from 'react';
import { OverlayContainer } from 'react-aria';

import Modal from 'components/Modal';
import NavigationBar from '../NavigationBar';

const Header: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return open ? (
    <OverlayContainer>
      <Modal isOpen onClose={() => setOpen(false)} isDismissable>
        <NavigationBar menuOpen={open} toggleMenu={() => setOpen(false)} />
      </Modal>
    </OverlayContainer>
  ) : (
    <NavigationBar menuOpen={open} toggleMenu={() => setOpen(true)} />
  );
};

export default Header;
